<template>
  <div class="carType">
    <!-- alertMsg -->
    <v-alert :type="type" v-if="type">
      {{ alertMsg }}
    </v-alert>

    <!-- delete dialog box -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Confirmation
        </v-card-title>

        <v-card-text>
          <br />
          Are you sure you want to delete this car type:
          <b>{{ delCarType.name }}</b
          >?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="doDelCarType()">
            Yes
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add car dialog box -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add Car Type
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="addCarType.name" label="Name"></v-text-field>
          <v-text-field
            v-model="addCarType.description"
            label="Description"
          ></v-text-field>
          <v-text-field v-model="addCarType.tag" label="Tag"></v-text-field>
          <v-file-input
            @change="chooseImage"
            v-model="imgFile"
            clearable
            label="Logo"
            accept="image/jpg,image/jpeg,image/png,image/gif"
          ></v-file-input>
          <v-switch
            v-model="addCarType.recommendFlag"
            class="ma-2"
            label="Featured"
          ></v-switch>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doAddCarType()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit dialog box -->
    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit Car Type
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="editCarType.name" label="Name"></v-text-field>
          <v-text-field
            v-model="editCarType.description"
            label="Description"
          ></v-text-field>
          <v-text-field v-model="editCarType.tag" label="Tag"></v-text-field>
          <v-file-input
            label="Logo"
            @change="chooseImage"
            v-model="editImgFile"
            clearable
            accept="image/jpg,image/jpeg,image/png,image/gif"
          ></v-file-input>
          <v-switch
            v-model="editCarType.recommendFlag"
            class="ma-2"
            label="Featured"
          ></v-switch>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditCarType()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- search box -->
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchType()"
          v-model="nameSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchType()"
          @keyup.enter.native="searchType()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
    </v-row>

    <!-- floating button [for add] -->
    <v-btn
      fab
      absolute
      class="plusButton"
      right
      bottom
      color="red accent-2"
      @click="dialog = !dialog"
    >
      <v-icon color="#fff">mdi-plus</v-icon>
    </v-btn>

    <!-- pagination table -->
    <v-data-table
      :headers="headers"
      :items="carType"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.id }}</td>
          <td>{{ row.item.name }}</td>
          <td>{{ row.item.description }}</td>
          <td>
            <img
              style="height:50px; padding-top: 6px"
              v-if="row.item.logoPath != null"
              v-bind:src="
                'https://ucars-bucket.s3-ap-southeast-1.amazonaws.com/' +
                  row.item.logoPath
              "
            />
          </td>
          <td>{{ row.item.recommendFlag }}</td>
          <td>{{ row.item.status }}</td>

          <!-- tbl row edit btn -->
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="primary"
              @click="editButtonClick(row.item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <!-- tbl row del btn -->
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="red"
              @click="delButtonClick(row.item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import uploadImage from "../../../services/image-uploader";

import postAxios from "../../../services/axios-post";
import putAxios from "../../../services/axios-put";
import getAxios from "../../../services/axios-get";
import deleteAxios from "../../../services/axios-delete";

Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      type: null,
      alertMsg: null,
      pageCount: 0,
      imgFile: null,
      editImgFile: null,
      imgUrl: null,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      addCarType: {
        tag: null,
        name: null,
        description: null,
        logoPath: null,
        recommendFlag: 0,
      },
      editCarType: {
        tag: null,
        id: "",
        name: "",
        description: "",
        logoPath: "",
        recommendFlag: 0,
      },
      delCarType: {
        id: "",
        name: "",
      },
      nameSearch: "",
      total: 0,
      dialog: false,
      editDialog: false,
      deleteDialog: false,
      token: "",
      headers: [
        { text: "Id", value: "id" },
        { text: "Car Type", value: "name" },
        { text: "Description", value: "description" },
        { text: "Logo", value: "logoPath" },
        { text: "Featured", value: "recommendFlag" },
        { text: "Status", value: "status" },
        { text: "Edit" },
        { text: "Delete" },
      ],
      carType: [],
    };
  },
  mounted() {
    this.fetchCredentials();
    this.fetchCarType();
  },
  methods: {
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchCarType();
    },
    delButtonClick(item) {
      this.delCarType.id = item.id;
      this.delCarType.name = item.name;
      this.deleteDialog = true;
    },
    editButtonClick(item) {
      this.editCarType.id = item.id;
      this.editCarType.code = item.code;
      this.editCarType.name = item.name;
      this.editCarType.description = item.description;
      this.editCarType.logoPath = item.logoPath;
      this.editCarType.recommendFlag = item.recommendFlag;
      this.editCarType.tag = item.tag;
      this.editDialog = true;
    },
    doDelCarType() {
      const self = this;
      const param = {
        carTypeId: self.delCarType.id,
      };
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/delCarType`,
        param,
        self.token
      ).then(res => {
        if (res.data) {
          self.deleteDialog = false;
          self.fetchCarType();
          self.$message({
            message: "Car Type Deleted Successfully!",
            type: "success",
          });
        }
      });
    },
    async doEditCarType() {
      const self = this;
      let attachmentPath = "";

      let featured = 0;
      if (self.editCarType.recommendFlag) {
        featured = 1;
      }

      try {
        if (self.editImgFile != null) {
          const imgRes = await uploadImage(self.editImgFile);
          if (imgRes.data.httpStatus !== "OK") {
            console.log(imgRes.data.desc);
            return;
          }
          attachmentPath = imgRes.data.data;
        }
        const params = {
          id: self.editCarType.id,
          code: self.editCarType.name,
          name: self.editCarType.name,
          description: self.editCarType.description,
          logoPath: attachmentPath ? attachmentPath : null,
          recommendFlag: featured,
          tag: self.editCarType.tag,
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/updCarType`,
          params,
          self.token
        ).then(res => {
          if (res.data) {
            self.editDialog = false;
            self.fetchCarType();
            self.showAlert("success", "Car Type Edited Successfully!");
          }
        });
      } catch (e) {
        self.showAlert("error", e);
        console.error(e);
      }
    },
    async doAddCarType() {
      const self = this;
      try {
        var attachmentPath = null;
        if (self.imgFile) {
          const imgRes = await uploadImage(self.imgFile);
          attachmentPath = imgRes.data.data;
        }
        let featured = 0;
        if (self.addCarType.recommendFlag) {
          featured = 1;
        }
        const params = {
          name: self.addCarType.name,
          description: self.addCarType.description,
          logoPath: attachmentPath ? attachmentPath : null,
          recommendFlag: featured,
          tag: self.addCarType.tag,
        };
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/addCarType`,
          params,
          self.token
        ).then(res => {
          if (res.data) {
            self.dialog = false;
            self.fetchCarType();
            self.showAlert("success", "New Car Type Added!");
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    chooseImage() {
      this.addCarType.logoName = this.imgFile.name;
      this.imgUrl = URL.createObjectURL(this.imgFile);
    },
    searchType() {
      this.fetchCarType();
    },
    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchCars();
    },
    handlePageSizeChange(newPageSize) {
      this.pagination.limit = newPageSize;
      const newPage = this.total / newPageSize;
      if (this.pagination.page > newPage) {
        this.pagination.page = Math.ceil(newPage);
      }
      this.fetchCars();
    },
    fetchCarType() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.nameSearch,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/carType`,
        params,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.carType = res.data.data.content;
            self.pagination.noOfPage = res.data.data.totalPages;
          }
        })
        .catch(function(error) {
          self.showAlert("error", error);
        });
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
  },
};
</script>
<style>
.plusButton {
  margin: 50px;
}
</style>
